export default class Loader {
  constructor() {
    this.version = '1.0.0';
    this.options = Loader.defaultOptions();
  }

  static defaultOptions() {
    return {
      numberDots: 3,
      during: 4000,
      colorDots: '#fff',
    };
  }

  setOptions(options) {
    this.options = Loader.defaultOptions();
    if (typeof options === 'object') {
      // this.options.numberDots = (typeof options.numberDots === 'number') ? options.numberDots;
      this.options.during = (typeof options.during === 'number') ? options.during : this.options.during;
      this.options.colorDots = (typeof options.colorDots === 'string') ? options.colorDots : this.options.colorDots;
    } else if (typeof options !== 'boolean' && typeof options !== 'undefined') {
      console.log('Error: the loader parameters is not a boolean or object.');
    }
  }

  static createOverlay() {
    const overlay = document.createElement('div');
    overlay.classList.add('sld-loader-overlay');
    return overlay;
  }

  createLoader() {
    const loader = document.createElement('div');
    loader.classList.add('sld-loader-content');
    for (let i = 0; i < this.options.numberDots; i += 1) {
      const dot = document.createElement('span');
      dot.classList.add('sld-loader-dot');
      dot.classList.add(`dot-${i}`);
      dot.style.backgroundColor = this.options.colorDots;
      loader.appendChild(dot);
    }
    return loader;
  }

  show(options) {
    if (options === false) {
      this.overlay.parentNode.removeChild(this.overlay);
      this.displayLoader = false;
    } else {
      this.setOptions(options);
      this.overlay = Loader.createOverlay();
      const loader = this.createLoader();
      this.overlay.appendChild(loader);
      document.body.appendChild(this.overlay);
      this.displayLoader = true;
    }
    setTimeout(() => {
      this.overlay.remove();
    }, this.options.during);
  }
}
