export default class Toast {
  constructor() {
    this.version = '1.1.0';
    this.toasts = [];
    this.timer = 0;
    this.options = Toast.defaultOptions();
  }

  static defaultOptions() {
    return {
      message: 'Hello world !',
      during: 8000,
    };
  }

  setOptions(options) {
    this.options = Toast.defaultOptions();
    if (typeof options === 'object') {
      this.options.message = (typeof options.message === 'string') ? options.message : this.options.message;
      this.options.during = (typeof options.during === 'number') ? options.during : this.options.during;
    } else if (typeof options === 'string') {
      this.options.message = options || this.options.message;
    } else {
      console.log('Error : the toast parameters is not string or object.');
    }
  }

  show(options) {
    // init options toast
    this.setOptions(options);
    // init container if is the first toast
    if (this.toasts.length === 0) {
      this.container = Toast.createContainer();
      this.startTimer();
      this.eventHoverToStop();
    }
    // init toast and insert message
    const toast = this.creatToast(this.options.during);
    // push toast object in this.toasts
    this.toasts.push(toast);
    // push toast in the container
    this.container.appendChild(toast.element);
  }

  static createContainer() {
    // create element to contain toasts
    const container = document.createElement('div');
    container.classList.add('sld-toast-container');
    document.body.appendChild(container);
    return container;
  }

  creatToast(during) {
    // create toast object
    const toast = {
      element: document.createElement('div'),
      timeToDie: this.toastDuring(during),
      die: false,
    };
    // add class to element in object
    toast.element.classList.add('sld-toast');
    toast.element.innerHTML = this.options.message;
    return toast;
  }

  removeToasts() {
    let index;
    // when there is no more toasts
    if (this.toasts.length === 0) {
      // remove container
      document.body.removeChild(this.container);
      delete this.container;
      // reset options
      this.options = Toast.defaultOptions();
      // init timer
      this.removeTimer();
    } else {
      for (index = 0; index < this.toasts.length; index += 1) {
        this.removeToast(index, this.toasts[index]);
      }
    }
  }

  removeToast(index, toastElement) {
    const toast = toastElement;
    // if toast timer is smaller to global timer
    if (toast.timeToDie <= this.timer && toast.timeToDie !== 0) {
      const toastHeight = toast.element.offsetHeight;
      toast.element.style.marginTop = `-${toastHeight}px`;
      toast.element.classList.add('sld-toast-remove');
      if (toast.element.classList.contains('sld-toast-remove') && toast.timeToDie !== 0) {
        // init set timeout to deleted the object
        setTimeout(() => {
          if (typeof toast !== 'undefined') {
            toast.die = true;
          }
        }, 600);
        // init timer toast
        toast.timeToDie = 0;
      }
    } else if (toast.die === true && toast.timeToDie === 0) {
      // remove toast object
      if (index === 0) {
        this.toasts.shift();
      } else {
        this.toasts.splice(index, index);
      }
    }
  }

  eventHoverToStop() {
    // add event on hover
    this.container.addEventListener('mouseover', () => {
      this.stopTimer();
    });
    // add event on stop
    this.container.addEventListener('mouseout', () => {
      this.startTimer();
    });
  }

  startTimer() {
    // start timer for
    this.initTimer = setInterval(() => {
      this.timer += 200;
      this.removeToasts();
    }, 200);
  }

  stopTimer() {
    // stop timer
    clearInterval(this.initTimer);
  }

  toastDuring(during) {
    // return the during to display toast
    let duringToast = this.during;
    if (typeof during !== 'undefined') {
      duringToast = during;
    }
    return this.timer + duringToast;
  }

  removeTimer() {
    // stop timer and init
    this.stopTimer();
    this.timer = 0;
    delete this.initTimer;
  }
}
