import soledis from './global';

const sldSearchWidgetRemoveClick = () => {
  const searchBar =document.querySelector('#header .search-widget-form');
  const button = document.querySelector('#header .search-widget-button');
  if (searchBar !== null && button !== null) {
    button.addEventListener('click', (e) => {
      let field = document.querySelector('#header .search-widget-field');
      if (!searchBar.classList.contains('active')) {
        e.preventDefault();
        searchBar.classList.add('active');
        field.focus();
        let overlay = document.createElement('div');
        overlay.classList.add('sld-overlay');
        overlay.addEventListener('click', () => {
          searchBar.classList.remove('active');
          overlay.remove();
        })
        document.body.append(overlay);
      }
    });
  }
}

const sldMeaCategoriesSlider = () => {
  const meaCategories = document.querySelectorAll('.sld-mea-categories-slider > .elementor-container > .elementor-row');
  if (meaCategories.length > 0) {
    $(meaCategories).slick({
      centerMode: true,
      dots: false,
      arrow: true,
      slidesToShow: 4,
      variableWidth: true,
      centerPadding: '260px',
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 4,
            infinite: true,
            centerPadding: '100px',
          }
        },
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 3,
            infinite: true,
            centerPadding: '100px',
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            centerPadding: '100px',
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerPadding: '100px',
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            centerPadding: '20px',
          }
        }
      ]
    });
  }
}

const sldDisplaySearchMobile = () => {
  const btnDisplay = document.querySelector('.sld-js-search-mobile-display') ;
  const headerBottom = document.querySelector('.mobile-header-version .header-bottom');
  const searchBar = document.querySelector('.mobile-header-version .header-bottom .search-widget-field');
  if (btnDisplay !== null && headerBottom !== null && searchBar !== null) {
    btnDisplay.addEventListener('click', (e) => {
      e.preventDefault();
      searchBar.focus();
    })
  }
}

const sldContainerFull = () => {
  const containers = document.querySelectorAll('.sld-js-container-full');
  const containerGlobale = document.querySelector('.center-wrapper');
  if (containers.length > 0 && containerGlobale !== null) {
    containers.forEach(item => {
      const containerWidth = containerGlobale.offsetWidth;
      const windowWidth  = document.body.offsetWidth;
      item.style.marginLeft = -((windowWidth - containerWidth) / 2) + 'px';
      item.style.width = windowWidth + 'px';
    }) 
  }
}

document.addEventListener('DOMContentLoaded', () => {
  // remove comment if you want the animation to display search bar
  sldSearchWidgetRemoveClick();
  sldMeaCategoriesSlider();
  sldDisplaySearchMobile();
  sldContainerFull();
});

window.addEventListener('resize', sldContainerFull);