import Soledis from './soledis';

const sld = new Soledis();

((window) => {
  const w = window;
  w.sld = sld;
})(window);

sld.init();
